import React, { Component } from "react";
import { Link } from "@reach/router";
import HeaderWelcome from "./components/HeaderWelcome";

import translations from "./config/translations";

class WelcomeBack extends Component {
  render() {
    return (
      <div className="content welcome-page">
        <div className="welcome-page__element">
          <HeaderWelcome siteTitle={this.props.translations.siteTitleWelcome} />
          <p className="welcome-page__name">
            {this.props.location.state
              ? `${this.props.location.state.visitor}!`
              : ""}
          </p>
          <ul className="home-page__buttons-wrapper-container">
            <li className="home-page__buttons-wrapper">
              <Link to="/check-in" className="button button--check-in">
                {translations.buttonMessage}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default WelcomeBack;
