import React, { Component } from "react";
import { Link } from "@reach/router"
import Header from "./components/Header";

import translations from "./config/translations";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="content">
        <Header
          siteTitle={translations.siteTitle}
          siteMessage={translations.siteMessage}
        />
        <ul className="home-page__buttons-wrapper-container centered">
          <li className="home-page__buttons-wrapper">
            <Link to="check-in" className="button button--check-in">
              {translations.checkInButton}
            </Link>
          </li>
        </ul>
      </div>
    );
  }
}

export default Home;
