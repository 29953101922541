import React, { Component } from "react";

class HeaderWelcome extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div>
        <h1 className="welcome__title">{this.props.siteTitle}</h1>
      </div>
    );
  }
}

export default HeaderWelcome;
