import React from "react";
import ShadowSelect from "./ShadowSelect";
import axios from "axios";
import params from "../config/parameters";

const visitorInput = {
    width: "243px"
  },
  input = {
    width: "86px"
  },
  visitorSelect = {
    display: "none",
    width: "243px",
    top: "46px",
    left: "5px"
  },
  visitorInputSelect = "visitor-input visitor-select";

class Select extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      staff: []
    };

    this.handleChange = this.handleChange.bind(this);
    this.getStaffMembers = this.getStaffMembers.bind(this);
  }

  componentDidMount() {
    let staffMembers = this.state.staff;

    this.getStaffMembers()
      .then(response => {
        const members = JSON.parse(response.data.data).sort((a, b) =>
          a.merge_fields.FNAME.localeCompare(b.merge_fields.FNAME)
        );

        this.setState({ staff: members });
      })
      .catch(error => {
        console.log(error);
      });
  }

  getStaffMembers() {
    return axios.get(`${params.server}/members`);
  }

  handleChange(event) {
    var index = event.nativeEvent.target.selectedIndex;
    let optionText = event.nativeEvent.target[index].text;

    if (this.props.onChange) {
      this.props.onChange({
        email: event.target.value,
        name: optionText
      });
    }
  }

  render() {
    const { selectedOption } = this.state;

    return (
      <div>
        <div className={`visitor-text-line visitor-select-wrapper`}>
          <div>
            <select
              id="visitors_idMembers"
              name="visitors[idMembers]"
              className={`${visitorInputSelect} `}
              style={visitorInput}
              value={this.state.value}
              onChange={this.handleChange}
            >
              <option defaultValue>Please select a staff member</option>
              {this.state.staff.map(member => (
                <option key={member.email_address} value={member.email_address}>
                  {member.merge_fields.FNAME} {member.merge_fields.LNAME}
                </option>
              ))}
            </select>
          </div>
          <ShadowSelect />
        </div>
      </div>
    );
  }
}

export default Select;
