import React, { Component } from "react";
import { Router } from "@reach/router"

import Home from "./Home";
import CheckIn from "./CheckIn";
import ThankYou from "./ThankYou";

import translations from "./config/translations";
import WelcomeBack from "./WelcomeBack";

class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  
  render() {
    return (
      <div>
        <Router>
          <Home 
            path="/" 
            translations={translations} 
          />
          <CheckIn 
            path="check-in" 
            translations={translations} 
          />
          <ThankYou 
            path="thanks" 
            translations={translations} 
            iframeSrc="https://1000heads.com" 
            location={this.props.location} 
          />
          <WelcomeBack
            path="welcome"
            translations={translations} 
            location={this.props.location} 
          />
        </Router>
      </div>
    );
  }
}

export default Main;
