import React from "react";

const maxLength = "225",
  type = "text",
  classList = "visitor-input expandable-input-width",
  divStyle = {
    position: "relative",
    display: "inline-block"
  };

class TextInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      errorMessage: "This field must be filled"
    };

    this.autoWidth = this.autoWidth.bind(this);
  }

  autoWidth(event) {
    let inputValue = event.target.value;
    this.setState({
      value: inputValue
    });

    if (this.props.onEntry) {
      this.props.onEntry(inputValue);
    }

    event.target.style.width = (event.target.value.length + 1) * 10 + "px";
  }

  render() {
    return (
      <div style={divStyle}>
        <input
          type={type}
          id={this.props.id}
          name={this.props.name}
          maxLength={maxLength}
          className={classList}
          value={this.state.value}
          onChange={this.autoWidth}
        />
        <div className="input-error" id={`${this.props.id}Error`}>
          {this.state.errorMessage}
        </div>
      </div>
    );
  }
}

export default TextInput;
