import React, { Component } from "react";
import { Link } from "@reach/router";
import Header from "./components/Header";

import translations from "./config/translations";

class ThankYou extends Component {
  render() {
    console.log(this.props);
    return (
      <div className="content thanks-page">
        <div className="thanks-page__element--top">
          <Header
            siteTitle={this.props.translations.siteTitleThankYou}
            staffName={
              this.props.location.state
                ? `${this.props.location.state.name} has`
                : "They have"
            }
            siteMessage={this.props.translations.siteMessageThankYou}
          />
          <ul className="home-page__buttons-wrapper-container">
            <li className="home-page__buttons-wrapper">
              <Link to="/check-in" className="button button--check-in">
                {translations.buttonMessage}
              </Link>
            </li>
          </ul>
        </div>

        <div className="our-work-iframe__title">
          {translations.iframeMessage}
        </div>

        <div className="thanks-page__element--bottom iframe-container">
          <iframe
            title="1000heads"
            className="our-work-iframe"
            src={this.props.iframeSrc}
          />
        </div>
      </div>
    );
  }
}

export default ThankYou;
