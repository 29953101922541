import React, { Component } from "react";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div>
        <h1 className="welcome__title">{this.props.siteTitle}</h1>
        <p className="welcome__message">
          {this.props.staffName ? this.props.staffName : ""}
          {this.props.siteMessage}
        </p>
      </div>
    );
  }
}

export default Header;
