export default {
	siteTitle: "HELLO",
	siteTitleThankYou: "THANK YOU",
	siteTitleWelcome: "WELCOME BACK",
	siteMessage: "Welcome to 1000heads",
	siteMessageThankYou: " been told you're here.",
	buttonMessage: "Check in another person ",
	iframeMessage: " Browse our work ",
	aboutYou: "Tell us a bit about yourself so we can tell the right person you’re here.",
	visitorName: "My name is ",
	visitorWork: "I work for ",
	visitorContact: "I'm here to meet ",
	newsLetter: "Would you like to receive the monthly 1000heads newsletter?",
	visitorEmail: "My email is ",
	submit: "All done",
	reset: "Start again",
	checkInButton: " Just arrived? Check in ",
	trueMessage: " Yes, please ",
	falseMessage: " No, thanks "
};
