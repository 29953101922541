import React from "react";

const shadowParent = "selectize-dropdown single visitor-input visitor-select",
  shadowChild = "selectize-dropdown-content";

const visitorSelect = {
  display: "none",
  width: "243px",
  top: "46px",
  left: "5px"
};

class ShadowSelect extends React.Component {
  render() {
    return (
      <div className={shadowParent} style={visitorSelect}>
        <div className={shadowChild} />
      </div>
    );
  }
}

export default ShadowSelect;
