import React, { Component } from "react";
import { navigate, Link } from "@reach/router";
import Header from "./components/Header";
import TextInput from "./components/Input";
import Select from "./components/Select";

import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import params from "./config/parameters";
import translations from "./config/translations";

const DROPDOWN_YES_VALUE = "true",
  visitorText = "visitor-text-line",
  visitorTextMain = "visitor-text-main-line",
  emailWrapper = `${visitorText} visitor-email-wrapper`;

const isShown = value => DROPDOWN_YES_VALUE === value;

class CheckIn extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.submitFormData = this.submitFormData.bind(this);
    this.handleVisitorName = this.handleVisitorName.bind(this);
    this.handleVisitorCompany = this.handleVisitorCompany.bind(this);
    this.handleVisitorEmail = this.handleVisitorEmail.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.showToast = this.showToast.bind(this);

    this.state = {
      isShown: false,
      value: "false",
      waitingTime: 300000,
      eventToListen: "click",
      staffOpts: [],
      staff: [],
      staffName: "",
      staffEmail: "",
      visitorName: "",
      visitorCompany: "",
      visitorEmail: "",
      toastId: "warning",
      images: [
        "https://media.giphy.com/media/l0MYGtCMbPTYWOzaU/giphy.gif",
        "https://media.giphy.com/media/o5oLImoQgGsKY/giphy.gif",
        "https://media.giphy.com/media/l0MYGtCMbPTYWOzaU/giphy.gif"
      ]
    };
  }

  showToast() {
    if (!toast.isActive(this.state.toastId)) {
      toast.error(
        "Sorry, we couldn't check you in. Please check your entry and try again",
        {
          toastId: this.state.toastId,
          position: toast.POSITION.TOP_CENTER,
          bodyClassName: "toast-style"
        }
      );
    }
  }

  submitFormData(e) {
    e.preventDefault();

    let random = Math.floor(
      Math.random() * Math.floor(this.state.images.length)
    );

    // Setup email data structure
    let data = {
      name: this.state.visitorName,
      company: this.state.visitorCompany,
      email: this.state.visitorEmail,
      from: params.receptionEmail,
      recipient: this.state.staffEmail,
      subject:
        this.state.visitorName +
        (this.state.visitorCompany
          ? ` from ${this.state.visitorCompany}`
          : ``) +
        " is waiting for you in Reception",
      message: `Hi ${this.state.staffName}, 
			<br/><br/>${this.state.visitorName} ${
        this.state.visitorCompany ? `from ${this.state.visitorCompany}` : ``
      } has arrived and is waiting in the reception area for you. <br/><br/>
			Thank you.<br/> 1000visitors 
			<br/><br/>
			<img src='${this.state.images[random]}' width="25%">
			<br/><br/>
			If something doesn't look right please email <a href='mailto:technical@1000heads.com?subject=1000 Visitors'>technical@1000heads.com</a> or message us on <a href='slack://user?team=T02FGG1KS&id=C6JKCPLG7'>Slack</a>.`
    };

    // Get users list from Slack
    if (
      this.state.staffEmail &&
      this.state.visitorName &&
      this.state.staffEmail
    ) {
      axios
        .get("https://slack.com/api/users.list?token=" + params.slackToken)
        .then(res => {
          let user = res.data.members.filter(
            user => user.profile.email === this.state.staffEmail.toLowerCase()
          );
          data.slackId = user ? user[0].id : null;
          this.submitCheckIn(data);
        })
        .catch(err => {
          console.error(err);
          this.submitCheckIn(data);
        });
    } else {
      this.showToast();
    }
  }

  /** Submit the check in, add to mail list if necessary and navigate */
  submitCheckIn(data) {
    let slackMessage = `Hi ${this.state.staffName}. ${this.state.visitorName}${
      this.state.visitorCompany ? ` from ${this.state.visitorCompany}` : ``
    } is waiting for you in Reception ⏰`;

    if (data.email) {
      axios
        .post(`${params.server}/members`, {
          email: data.email,
          name: data.name
        })
        .then(res => {
          axios
            .post(
              "https://slack.com/api/chat.postMessage?token=" +
                params.slackToken +
                "&channel=" +
                data.slackId +
                "&text=" +
                slackMessage
            )
            .then(res => {
              if (params.welcome.includes(this.state.visitorName)) {
                navigate("/welcome", {
                  state: { visitor: this.state.visitorName },
                  replace: true
                });                
              } else {
                navigate("/thanks", {
                  state: { name: this.state.staffName },
                  replace: true
                });
              }
            })
            .catch(err => {
              if (params.welcome.includes(this.state.visitorName)) {
                navigate("/welcome", {
                  state: { visitor: this.state.visitorName },
                  replace: true
                });                
              } else {
                navigate("/thanks", {
                  state: { name: this.state.staffName },
                  replace: true
                });
              }
            });
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      axios
        .post(params.server, data)
        .then(res => {
          axios
            .post(
              "https://slack.com/api/chat.postMessage?token=" +
                params.slackToken +
                "&channel=" +
                data.slackId +
                "&text=" +
                slackMessage
            )
            .then(res => {
              if (params.welcome.includes(this.state.visitorName)) {
                navigate("/welcome", {
                  state: { visitor: this.state.visitorName },
                  replace: true
                });                
              } else {
                navigate("/thanks", {
                  state: { name: this.state.staffName },
                  replace: true
                });
              }
            })
            .catch(err => {
              if (params.welcome.includes(this.state.visitorName)) {
                navigate("/welcome", {
                  state: { visitor: this.state.visitorName },
                  replace: true
                });                
              } else {
                navigate("/thanks", {
                  state: { name: this.state.staffName },
                  replace: true
                });
              }
            });
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  inactivityDetector(settings) {
    var BODY_ELEMENT_NAME = "body",
      DEFAULT_EVENT_TO_LISTEN = "click";

    var lastClick = void 0,
      redirectUrl = void 0,
      waitingTime = void 0,
      eventToListen = void 0,
      $body = void 0;

    var setDOMElements = function setDOMElements() {
      return ($body = document.querySelector(BODY_ELEMENT_NAME));
    };

    var setLastClick = function setLastClick() {
      return (lastClick = Date.now());
    };

    var setRedirectUrl = function setRedirectUrl(settings) {
      var origin = window.location.origin;

      redirectUrl = settings ? settings.redirectUrl || origin : origin;
    };

    var setWaitingTime = function setWaitingTime(settings) {
      var defaultWaitingTime = 300000; // 5 minutes by default

      waitingTime = settings
        ? settings.waitingTime || defaultWaitingTime
        : defaultWaitingTime;
    };

    var setEventsToListen = function setEventsToListen(settings) {
      eventToListen = settings
        ? settings.eventToListen || DEFAULT_EVENT_TO_LISTEN
        : DEFAULT_EVENT_TO_LISTEN;
    };

    var bindEvents = function bindEvents() {
      $body.addEventListener(eventToListen, setLastClick);
    };

    var checkActivity = function checkActivity() {
      if (Date.now() - lastClick >= waitingTime) {
        window.location.href = redirectUrl;
        return false;
      }

      setTimeout(function() {
        return requestAnimationFrame(checkActivity);
      }, waitingTime);
    };

    var startInactivityDetector = function startInactivityDetector() {
      return requestAnimationFrame(checkActivity);
    };

    var init = function init(settings) {
      setDOMElements();
      setLastClick();
      setRedirectUrl(settings);
      setWaitingTime(settings);
      setEventsToListen(settings);
      bindEvents();
      startInactivityDetector();
    };

    init(settings);
  }

  handleChange(event) {
    let targetValue = event.target.value;

    this.setState({
      value: targetValue,
      isShown: isShown(targetValue)
    });
  }

  handleVisitorName(nameValue) {
    this.setState({ visitorName: nameValue });
  }

  handleVisitorCompany(companyValue) {
    this.setState({ visitorCompany: companyValue });
  }

  handleVisitorEmail(emailValue) {
    this.setState({ visitorEmail: emailValue });
  }

  handleSubmitChange(event) {
    this.showInputError(event.target.name);
  }

  handleSelect(memberObject) {
    this.setState({
      staffEmail: memberObject.email,
      staffName: memberObject.name.split(" ")[0]
    });
  }

  showFormErrors() {
    const inputs = document.querySelectorAll('input[name]:not([name=""])');

    let isFormValid = true;

    inputs.forEach(input => {
      input.classList.add("active");

      const isInputValid = this.showInputError(input);

      if (!isInputValid) {
        isFormValid = false;
      }
    });

    return isFormValid;
  }

  showInputError(input) {
    const inputID = input.id;

    const error = document.getElementById(`${inputID}Error`);
    let errorStyle = error.style;

    if (!input.value) {
      errorStyle.display = "block";
      return true;
    }

    errorStyle.display = "none";
  }

  render() {
    return (
      <div className="content check-in-content">
        <div className="check-in-content__top">
          <ToastContainer autoClose={10000} />
          <Header
            siteTitle={translations.siteTitle}
            siteMessage={translations.siteMessage}
          />
          <p className="welcome__message welcome__about-yourself">
            {translations.aboutYou}
          </p>
        </div>

        <div className="check-in-content__form">
          <form name="visitors" className="check-in-form">
            <div className={visitorTextMain}>
              <div className={visitorText}>
                {translations.visitorName}
                <TextInput
                  id="visitors_fullName"
                  name="visitors[fullName]"
                  value={this.props.visitorName}
                  onEntry={this.handleVisitorName}
                />
              </div>

              <div className={visitorText}>
                {translations.visitorWork}
                <TextInput
                  id="visitors_company"
                  name="visitors[company]"
                  onChange={this.handleSubmitChange}
                  onEntry={this.handleVisitorCompany}
                />
              </div>

              <div className={`${visitorText} visitor-custom-select`}>
                {translations.visitorContact}
                <Select onChange={this.handleSelect} />
              </div>
            </div>

            <div className={visitorTextMain}>
              <div className={`${visitorText} visitor-select-wrapper`}>
                <p className="visitor-newsletter-text-wrapper">
                  {translations.newsLetter}
                </p>
                <div>
                  <select
                    className="visitor-input visitor-select put-me-in-the-list"
                    onChange={this.handleChange}
                    value={this.state.value}
                  >
                    <option value="true">{translations.trueMessage}</option>
                    <option value="false">{translations.falseMessage}</option>
                  </select>
                </div>
              </div>

              <div className={visitorTextMain}>
                <div
                  className={
                    this.state.isShown
                      ? `${emailWrapper}`
                      : `${emailWrapper} hidden`
                  }
                >
                  {translations.visitorEmail}
                  <div>
                    <div>
                      <TextInput
                        id="visitors_email"
                        name={this.state.isShown ? "visitors[email]" : ""}
                        value={this.state.visitorEmail}
                        onEntry={this.handleVisitorEmail}
                      />
                      <span className="input-text-holder" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="visitor-submit-button-container">
                <div>
                  <button
                    type="submit"
                    id="visitors_save"
                    name="visitors[save]"
                    className="visitor-submit-button button"
                    onClick={this.submitFormData}
                  >
                    {translations.submit}
                  </button>
                </div>
              </div>

              <Link to="/" className="start-again-link">
                {translations.reset}
              </Link>
            </div>
          </form>
        </div>

        {this.inactivityDetector({
          waitingTime: this.state.waitingTime,
          eventToListen: this.state.eventToListen,
          redirectUrl: params.baseUrl
        })}
      </div>
    );
  }
}

export default CheckIn;
